<template>
	<div>
		<div class="b-container">
			<div class="b-wrapper-page">
				<div :class="{'b-filter': true}">
					<button class="b-filter__button">
						<h2>Подписки</h2>
					</button>
					<form class="b-filter__hidden" onsubmit="return false">
						<div class="b-filter__form">
							<div class="b-input b-input--dual b-input--dual b-input--clinical b-input--search input-group">
									<!-- Наименование КР -->
								<div class="b-input b-input--dual b-input--dual b-input--clinical b-input--search">
									<span class="email-text">Введите ваш e-mail для подписки</span>
									<input id="search-filter-namee" placeholder="E-mail" v-model="email" class="b-input__input-field1 b-input__input-field--dual b-input__input-field--dual b-input__input-field--clinical1 b-input__input-field--search" type="search" name="search-filter-name" />
								</div>
							</div>
							<button @click="subscription2(email)" class="b-button b-button--fill">
								<span>Отправить</span>
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
    		<snackbar position="top-center" ref="snackbar" baseSize="100px" :wrapClass="''" color="info" :holdTime="4000"/>
		</div>
</template>

<script>
import iconSvg from "../../assets/icons/svg/filter.svg"
import iconCloseSvg from "../../assets/icons/svg/close.svg"
import iconSearchSvg from "../../assets/icons/svg/search.svg"
import iconAngleDownSvg from "../../assets/icons/svg/angle-down.svg"
import iconAngleleftSvg from "../../assets/icons/svg/angle-left.svg"
import iconAnglerightSvg from "../../assets/icons/svg/angle-right.svg"
import iconDownloadSvg from "../../assets/icons/svg/download.svg"
import iconSortdownSvg from "../../assets/icons/svg/sort-down.svg"
import iconSortupSvg from "../../assets/icons/svg/sort-up.svg"
import iconEditSvg from "../../assets/icons/svg/edit.svg"
import iconEyeSvg from "../../assets/icons/svg/eye.svg"

import { RingLoader } from '@saeris/vue-spinners'


import Dropdown from '../../components/Dropdown/index'
import Dashboards from '../../components/Dashboards/index'
import {mapGetters, mapActions} from 'vuex'

export default {
	name: 'listCr',
	components: { RingLoader, Dashboards, Dropdown, iconEditSvg, iconEyeSvg, iconSvg, iconSearchSvg, iconAngleDownSvg, iconCloseSvg, iconDownloadSvg, iconSortdownSvg, iconSortupSvg, iconAngleleftSvg, iconAnglerightSvg },
	computed: {
			...mapGetters({
				media: 'layout/getMedia',
				apiUrl: 'apiUrl',
				apiUrlPortal: 'apiUrlPortal',
			}),
	},
	data: () => ({
		email: '',
		rules: {
			required: v => (!!v || v === 0 || v.length === 0) || 'Обязательно для заполнения!',
			requiredMultiple: (v) => v.length > 0 || 'Обязательно для заполнения.',
		},
	}),
	methods: {
		...mapActions({
			apiAll: 'api/apiAll'
		}),
		subscription2(email) {
			this.api.post(`${this.apiUrl}api/Subscription/SendCode?email=${email}`)
		.then(res => {
			console.log(res);
			if(res.data.success) {
				this.$router.push(`/subscription-second/${email}`)
			}
			else{
				this.$refs.snackbar.info(res.data.userMessage);
			}
		})

		},
	},
	created() {

	}
}
</script>

<style scoped>
@import "../../views/Subscription/style/stylePages.css";
@import "../../views/Subscription/style/mobile.css";

.ring-load {
	position: absolute;
	cursor: progress;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	/* opacity: 0.4; */
	z-index: 10;
	background-color: rgba(88, 88, 88, 0.2);
}

.ring-load-loading {
	left: 42%;
    top: 42%;
}

table tbody tr:hover {
	background: #f1f1f1;
	box-shadow: 0 1.5px 6px #00000080;
	cursor: pointer;
}

</style>
